<template>
  <section id="finance-dashboard">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showFixedCosts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Real Fixed Costs
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-real-fixed-costs" />
              </h4>
              <b-popover target="popover-real-fixed-costs" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="realFixedCostsChart" :options="realFixedCostsChart" style="width: 100%; height: 400px;" />
          </b-card>
          <b-row>
            <b-col xl="6" md="6">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ realFixedCostsTotalAmount | formatCurrencyNumber }}
                    </h2>
                    <span>Real Fixed Costs Total Amount</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="6" md="6">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ realFixedCostsAvgPerMonth | formatCurrencyNumber }}
                    </h2>
                    <span>Real Fixed Costs Avg Per Month</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="8" md="8">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative" :current-page="realFixedCostsTable.currentPage"
              :items="realFixedCostsTable.items" :per-page="10" :fields="realFixedCostsTable.fields"
              :sort-by.sync="realFixedCostsTable.sortBy" :sort-desc.sync="realFixedCostsTable.sortDesc"
              :sort-direction="realFixedCostsTable.sortDirection" :filter="realFixedCostsTable.filter"
              :filter-included-fields="realFixedCostsTable.filterOn" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="realFixedCostsTable.currentPage" :total-rows="realFixedCostsTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4" md="4">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Variable Costs
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-variable-costs" />
              </h4>
              <b-popover target="popover-variable-costs" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="variableCostsChart" :options="variableCostsChart" style="width: 100%; height: 400px;" />
          </b-card>
          <b-row>
            <b-col xl="6" md="6">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ variableCostsTotalAmount | formatCurrencyNumber }}
                    </h2>
                    <span>Variable Costs Total Amount</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col xl="6" md="6">
              <b-card no-body>
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ variableCostsAvgPerMonth | formatCurrencyNumber }}
                    </h2>
                    <span>Variable Costs Avg Per Month</span>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="8" md="8">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative" :current-page="variableCostsTable.currentPage"
              :items="variableCostsTable.items" :per-page="10" :fields="variableCostsTable.fields"
              :sort-by.sync="variableCostsTable.sortBy" :sort-desc.sync="variableCostsTable.sortDesc"
              :sort-direction="variableCostsTable.sortDirection" :filter="variableCostsTable.filter"
              :filter-included-fields="variableCostsTable.filterOn" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="variableCostsTable.currentPage" :total-rows="variableCostsTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Real Fixed Costs development
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-real-fixed-costs-development" />
              </h4>
              <b-popover target="popover-real-fixed-costs-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="realFixedCostsDevelopmentChart" :options="realFixedCostsDevelopmentChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Variable Costs development
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-variable-costs-development" />
              </h4>
              <b-popover target="popover-variable-costs-development" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="variableCostsDevelopmentChart" :options="variableCostsDevelopmentChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(currentDate.getMonth() - 5);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showFixedCosts: true,
      oldDateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${sixMonthsAgo.getFullYear()}-${formatMonth(sixMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      queryParams: {},
      realFixedCostsTotalAmount: 0,
      realFixedCostsAvgPerMonth: 0,
      realFixedCostsChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Turnover',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      realFixedCostsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'amount',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'category', label: 'category', sortable: true },
          {
            key: 'amount',
            label: 'amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_per_month',
            label: 'avg per month',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      variableCostsTotalAmount: 0,
      variableCostsAvgPerMonth: 0,
      variableCostsChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Turnover',
            type: 'pie',
            radius: ['0%', '95%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
      variableCostsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'amount',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'category', label: 'category', sortable: true },
          {
            key: 'amount',
            label: 'amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'avg_per_month',
            label: 'avg per month',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      realFixedCostsDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      variableCostsDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    try {
      const dateRange = this.dateRange.split(' to ');
      this.queryParams.from_date = dateRange[0];
      this.queryParams.to_date = dateRange[1];

      await this.getData();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getData() {
      this.showFixedCosts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/finance-dashboard/`, this.queryParams);
        const results = response.data.results;

        const realFixedCostsByCategoryData = results['real_fixed_costs_by_category'];
        const variableCostsByCategoryData = results['variable_costs_by_category'];
        const realFixedCostsDevelopmentData = results['real_fixed_costs_development'];
        const variableCostsDevelopmentData = results['variable_costs_development'];

        // Calculate total amount
        this.realFixedCostsTotalAmount = realFixedCostsByCategoryData.reduce((acc, item) => acc + item.amount, 0);
        this.variableCostsTotalAmount = variableCostsByCategoryData.reduce((acc, item) => acc + item.amount, 0);

        // Calculate average per month
        this.realFixedCostsAvgPerMonth = realFixedCostsByCategoryData.reduce((acc, item) => acc + item.avg_per_month, 0);
        this.variableCostsAvgPerMonth = variableCostsByCategoryData.reduce((acc, item) => acc + item.avg_per_month, 0);

        // Real Fixed Costs
        this.realFixedCostsChart.series[0].data = realFixedCostsByCategoryData.map(item => {
          return {
            value: item.amount,
            name: item.category,
          };
        });
        this.realFixedCostsTable.items = realFixedCostsByCategoryData;
        this.realFixedCostsTable.totalRows = realFixedCostsByCategoryData.length * 2;

        // Variable Costs
        this.variableCostsChart.series[0].data = variableCostsByCategoryData.map(item => {
          return {
            value: item.amount,
            name: item.category,
          };
        });
        this.variableCostsTable.items = variableCostsByCategoryData;
        this.variableCostsTable.totalRows = variableCostsByCategoryData.length * 2;

        // Real Fixed Costs development
        let realFixedCostsByCategoryDatasets = [];
        const realMonthYearLabels = [...new Set(realFixedCostsDevelopmentData[7].data.map(item => item.month_year))];
        for (let i = 0; i < realFixedCostsDevelopmentData.length; i++) {
          let realFixedCostsByCategoryData = realMonthYearLabels.map(monthYear => {
            const item = realFixedCostsDevelopmentData[i].data.find(entry => entry.month_year === monthYear);
            return item ? item.total_amount : 0.0;
          });

          realFixedCostsByCategoryDatasets.push({
            name: realFixedCostsDevelopmentData[i].category,
            type: 'line',
            data: realFixedCostsByCategoryData,
          });
        }

        this.realFixedCostsDevelopmentChart.xAxis.data = realMonthYearLabels;
        this.realFixedCostsDevelopmentChart.series = realFixedCostsByCategoryDatasets;

        // Variable Costs development
        let variableCostsByCategoryDatasets = [];
        const variableMonthYearLabels = [...new Set(variableCostsDevelopmentData[3].data.map(item => item.month_year))];
        for (let i = 0; i < variableCostsDevelopmentData.length; i++) {
          let variableCostsByCategoryData = variableMonthYearLabels.map(monthYear => {
            const item = variableCostsDevelopmentData[i].data.find(entry => entry.month_year === monthYear);
            return item ? item.total_amount : 0.0;
          });

          variableCostsByCategoryDatasets.push({
            name: variableCostsDevelopmentData[i].category,
            type: 'line',
            data: variableCostsByCategoryData,
          });
        }

        this.variableCostsDevelopmentChart.xAxis.data = variableMonthYearLabels;
        this.variableCostsDevelopmentChart.series = variableCostsByCategoryDatasets;

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showFixedCosts = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getData();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.realFixedCostsChart.refresh();
          this.$refs.realFixedCostsDevelopmentChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>